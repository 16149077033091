.main-header {
  background-color: white;
  box-shadow: 0 0 20px 0 #1d1f591a;
  display: none;
  border-bottom: 1px solid #eee;
}
.main-header-inactive {
  left: 70px;
}
.main-header.non-left {
  left: 0 !important;
}

.main-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.main-header .container .logo {
  height: 45px;
  width: auto;
}

.main-header .container .logo img {
  /* width: 100%; */
  height: 100%;
}

.main-header .container .actions {
  display: flex;
  align-items: center;
}

.mobile-header {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
  height: 55px;
  min-height: 55px;
  background-color: #1a1a27;
}

.mobile-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
}

.mobile-header .container .logo {
  /* width: 92px; */
}

.mobile-header .container .logo.organization-name {
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.mobile-header .container .logo img {
  height: 100%;
}

.mobile-header .container .actions {
  display: flex;
  align-items: center;
}

.mobile-header .container .actions svg {
  font-size: 30px;
  color: #484f66;
}

.mobile-header .container .actions .active-icon {
  color: #348dea;
}

.mobile-header .container .actions .menu {
  margin-right: 10px;
}

.mobile-header .menu-hidden {
  box-shadow: 0px 0px 40px 0px rgb(82 63 105 / 20%);
  border-top: 1px solid #eff0f6;
  height: 70px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.3s;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 55px;
  width: 100%;
}

.mobile-header .menu-active {
  opacity: 1;
  pointer-events: all;
}

.mobile-header .menu-hidden .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.desktop-header-chat-icon {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.desktop-header-chat-icon img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 6px;
}

.desktop-header-chat-icon span {
  font-weight: bold;
  color: #389afe;
}

@media (min-width: 1000px) {
  .main-header {
    display: block;
  }
  .mobile-header {
    display: none;
  }
}

.item-have-check-icon.MuiListItem-root {
  padding-left: 40px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.item-have-check-icon.MuiListItem-root.Mui-selected::before {
  content: "";
  position: absolute;
  border-color: #474747;
  border-style: solid;
  border-width: 0 0.2em 0.2em 0;
  height: 0.6em;
  top: 12px;
  left: 16px;
  transform: rotate(45deg);
  width: 0.3em;
}

.menu-item-line img.item-image {
  height: auto;
  object-fit: contain;
}

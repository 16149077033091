.chart-modal-header {
  margin-bottom: 16px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-modal-header-icon {
  cursor: pointer;
}

.chart-modal-body {
}

.chart-modal-card {
  border: 2px solid lightgray;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* flex-direction: column; */
}

.chart-modal-card:hover {
  border: 2px solid #3699ff;
}

.chart-modal-card-text {
  padding: 10px 12px;
  background: #ececec;
  /* border-bottom-left-radius: 6px; */
  /* border-bottom-right-radius: 6px; */
  flex: 1;
  align-self: stretch;
}

.chart-modal-card-title {
  font-size: 16px;
  margin-bottom: 6px;
}

.chart-modal-card-description {
  margin: 0;
}

.chart-modal-card-image {
  /* flex: 1; */
  /* width: 100%; */
  /* height: 120px; */
  width: 82px;
  height: 72px;
  object-fit: contain;
  /* border-bottom: 1px solid lightgray; */
  /* border-top-right-radius: 6px; */
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.chart-modal-body .makeStyles-paper-30 {
  width: 50% !important;
}

.chart-modal-body .color-picker-color {
  width: 100% !important;
}

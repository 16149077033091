.user-header-enduser .name {
  font-size: 13px;
  color: #b5b5c3;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  padding: 4px 7px;
  border-radius: 5px;
}
.user-header-enduser .name:hover {
  /* background-color: #f3f6f9; */
}
.user-header-enduser .name span:first-child {
  margin-right: 4px;
}
.user-header-enduser .name span:nth-child(2) {
  margin-right: 10px;
  color: #7e8299;
  font-weight: 600;
}
.user-header-enduser .name span:last-child {
  display: inline-block;
  width: 35px;
  height: 35px;
  font-size: 16px;
  background-color: #c9f7f5;
  color: #1bc5bd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-sidebar.enduser {
  height: 100%;
  width: 309px;
  background: #ffffff;
  box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 25%);
  padding: 33px;
}
.user-sidebar.enduser .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.user-sidebar.enduser .header h3 {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

.user-sidebar.enduser .header .close {
  width: 24px;
  height: 24px;
  color: #7e8299;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

.user-sidebar.enduser .header .close svg {
  font-size: 15px;
  color: #b5b5c3;
  transition: all 0.3s;
}

.user-sidebar.enduser .header .close:hover {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}

.user-sidebar.enduser .header .close:hover svg {
  color: #ffffff;
}

.user-sidebar.enduser .content {
}

.user-sidebar.enduser .content .info {
  display: flex;
  align-items: center;
}

.user-sidebar.enduser .content .info .avatar {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  position: relative;
}

.user-sidebar.enduser .content .info .avatar img {
  object-fit: cover;
}

.user-sidebar.enduser .content .info .avatar span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #1bc5bd;
  border-radius: 50%;
  top: -6px;
  right: -6px;
  position: absolute;
  border: 2px solid #fff;
}

.user-sidebar.enduser .content .info .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.user-sidebar.enduser .content .main-info {
  padding-left: 16px;
}

.user-sidebar.enduser .content .main-info .name {
  font-size: 17px;
  margin-bottom: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.user-sidebar.enduser .content .main-info .name:hover {
  color: #3699ff;
}

.user-sidebar.enduser .content .main-info .role {
  color: #b5b5c3;
  margin-bottom: 10px;
}

.user-sidebar.enduser .content .main-info .mail {
  color: #b5b5c3;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.user-sidebar.enduser .content .main-info .mail svg {
  margin-right: 10px;
  font-size: 20px;
}

.user-sidebar.enduser .content .main-info .mail:hover {
  color: #3699ff;
}

.user-sidebar.enduser .content .main-info .button {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  margin-top: 8px;
}

.user-sidebar.enduser .content .seporator {
  border-bottom: 1px dashed #ebedf3;
  margin: 20px 0;
}

.user-sidebar.enduser .content .user-menus ul li {
  display: flex;
  padding: 10px 0;
}

.user-sidebar.enduser .content .user-menus ul a:last-child .icon {
  /* color: #ffa800; */
}

.user-sidebar.enduser .content .user-menus ul li .icon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  color: #1bc5bd;
  background-color: #f3f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-sidebar.enduser .content .user-menus ul li .menu-content {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
}

.user-sidebar.enduser .content .user-menus ul li .menu-content p {
  font-weight: 500;
  transition: all 0.3s;
  margin: auto;
  color: #4d4d4d;
}

.user-sidebar.enduser
  .content
  .user-menus
  ul
  li
  .menu-content:hover
  p:first-child {
  color: #3699ff;
}

.user-header-enduser .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-color: #3699ff;
  border-width: 1px;
}

#profile-menu li {
  font-size: 13px;
  transform: 0.4s;
  padding: 0px;
}

#profile-menu li a {
  width: 100%;
  padding: 6px 16px;
}

#profile-menu li:hover {
  background-color: rgba(54, 153, 255, 0.1);
}

#profile-menu li:hover a {
  color: #3699ff !important;
}

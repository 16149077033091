.circular-gauge {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  /* margin-bottom: 24px; */
}

.circular-gauge .gauge {
  position: relative;
  margin: 0 auto;
  top: 18%;
  height: 80%;
}

.circular-gauge svg {
  width: 100%;
}

.circular-gauge-title {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
  margin-bottom: 6px;
}

.circular-gauge-subtitle {
  color: gray;
  text-transform: uppercase;
  margin: 0;
}

.circular-gauge-time {
  color: gray;
  font-size: 0.9em;
  margin: 0;
  margin-top: 10px;
  text-align: center;
}

.circular-gauge-container {
  position: relative;
}

.circular-gauge-content {
  position: relative;
}

.circular-gauge-info {
  text-align: center;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
}

.circular-gauge-currentvalue {
  /* font-weight: 600; */
}

.circular-gauge-currentvalue p {
  margin-bottom: 5px;
}

.circular-gauge-currentstate {
  display: flex;
}

.circular-gauge-currentstate span {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-modal-reponsive {
  width: 50%;
  min-width: 700px;
}

@media screen and (max-width: 800px) {
  .card-modal-reponsive {
    width: 80%;
    min-width: 0px;
  }
}

.aggregate-table-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  text-align: center;
  background: white;
  outline: none !important;
  border: none !important;
  position: relative;
  border-radius: 6px;
  font-family: Helvetica;
  font-size: 15px;
}

.aggregate-table-card-main {
  overflow: auto;
  flex: 1;
  height: 100%;
  position: relative;
}

.aggregate-table-card table {
  width: 100%;
  /* height: 100%; */
  border-collapse: collapse;
  color: white;
}

.aggregate-table-card--landscape table {
  height: auto;
}

.aggregate-table-card th,
.aggregate-table-card td {
  padding: 6px;
  border: 1px solid white;
}

.aggregate-table-card table tr:nth-child(n) {
  background: #3699ff;
}

.aggregate-table-card table tr:nth-child(2n) {
  background: #66b3ff;
}

/* table.aggregate-table {
  table-layout: fixed;
  width: 100%;
} */

/* table.aggregate-table th {
  background-color: #004080;
} */

table.aggregate-table .aggregate-table-header {
  height: 10%;
}

.aggregate-table-card-buttons {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aggregate-table-card-image {
  opacity: 0.15;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  height: 50%;
  width: 50%;
  object-fit: contain;
  pointer-events: none;
}

.aggregate-table-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aggregate-table-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: #205635;
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: move;
  width: 100%;
}

.hidenMove .aggregate-table-card-title {
  cursor: default;
}

.aggregate-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.aggregate-table-card--landscape.aggregate-table-card--normal
  .aggregate-table-header
  th {
  position: sticky;
  top: 0;
}

.aggregate-table-card--landscape.aggregate-table-card--normal
  .aggregate-table-header
  th:first-child {
  left: 0;
  z-index: 5;
}

.aggregate-table-card--landscape.aggregate-table-card--normal
  .aggregate-table:nth-child(2)
  tr
  th {
  position: sticky;
  left: 0;
}

.aggregate-table-card--landscape .aggregate-table:nth-child(2) {
  background: #3699ff;
}

.aggregate-table-card--landscape.aggregate-table-card--normal
  .aggregate-table:nth-child(2)
  tr
  th::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid white;
}

.aggregate-table-card--portrait.aggregate-table-card--normal
  .aggregate-table-header
  th {
  position: sticky;
  top: 0;
}

.aggregate-table-card--portrait.aggregate-table-card--normal
  .aggregate-table-header
  th:first-child {
  left: 0;
  z-index: 5;
}

.aggregate-table-card--portrait.aggregate-table-card--normal
  .aggregate-table
  tr
  th {
  position: sticky;
  left: 0;
  background: #3699ff;
}

.aggregate-table-card--portrait .aggregate-table tr th {
  background: #3699ff;
}

.aggregate-table-card--portrait.aggregate-table-card--normal
  .aggregate-table
  tr
  th::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid white;
}

.aggregate-table-header th {
  background: #004080 !important;
}

.aggregate-table-card--landscape .aggregate-table th:first-child {
  background: #3699ff;
}

.aggregate-table-card--normal .aggregate-table-header th:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 1px solid white;
}

.aggregate-table-header-container {
  position: sticky;
  top: 0;
  z-index: 9;
}

.filters-tooltip {
  position: fixed;
  background-color: white;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.filters-on {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #ea3d3c;
  position: absolute;
  top: 0px;
  right: 0px;
}

.auth-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.auth-layout .background {
  padding: 36px 0;
  padding-bottom: 24px;
  text-align: center;
  background: url("../../../../assets/images/background-auth-layout.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.auth-layout .background h1 {
  text-transform: uppercase;
  font-size: 2em;
  color: white;
  font-weight: 400;
  margin: 0 0 6px 0;
  letter-spacing: 1px;
}
.auth-layout .background p{
  opacity: 0.5;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 12px;
}
.auth-layout .content {
  margin: 30px 0;
}
.station-header {
  padding: 16px;
  background: #3699ff;
  color: white;
  display: flex;
  align-items: center;
}

.station-name {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.station-address {
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  padding: 0;
  font-size: 12px;
  margin-top: 3px;
  font-weight: 500;
}

.station-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: -1;
}

.parameter {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 12px;
  border-top: none;
}

/* https://stackoverflow.com/questions/12692089/preventing-double-borders-in-css */
.parameter + .parameter {
  border-left: 0;
}

.parameter-icon svg {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 12px;
}

.parameter-name {
  flex: 1;
}

.parameter-value {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.parameter-number {
  font-size: 15px;
  font-weight: bold;
}

.parameter-unit {
  font-size: 10px;
  margin-left: 6px;
}

.last-recorded-time {
  background: #e1f0ff;
  color: #3699ff;
  text-align: center;
  font-size: 12px;
  padding: 12px;
  font-weight: 500;
}

.copyright {
  text-align: center;
  font-size: 12px;
  color: gray;
  margin-top: 12px;
}

.station-image {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 12px;
}

.parameter-alarm svg {
  color: #ea4d4e;
}

.parameter-alarm .parameter-value {
  color: #ea4d4e;
}

@media (max-width: 420px) {
  .station-main {
    grid-template-columns: 1fr;
  }
}

.image-wrapper {
  height: 140px;
  /* width: 240px; */
}
.image-wrapper .image-uploader-icon {
  width: 100%;
  height: 100%;
}

.image-wrapper .image {
  border-radius: 10px;
  border: 1px solid lightgray;
  width: 100%;
  max-height: 100%;
  height: auto;
  vertical-align: middle;
}

.custom-chart-card {
  border-radius: 6px;
  text-align: center;
  font-family: Helvetica;
  font-size: 15px;
  position: relative;
  height: 100%;
  background: white;
  overflow: hidden;
  outline: none !important;
  border: none !important;
}

.custom-chart-card:hover,
.custom-chart-card:focus,
.custom-chart-card:active {
  /* overflow: auto; */
}

.custom-chart-card-buttons {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.custom-chart-card-content {
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}

/* .custom-chart-card-content:hover,
.custom-chart-card-content:focus,
.custom-chart-card-content:active {
  overflow: auto;
} */

.custom-chart-card:hover .custom-chart-card-buttons {
  display: block;
}

.custom-chart-card-button {
  margin: 12px;
  font-weight: bold;
  cursor: pointer;
}

.custom-chart-card-button:hover {
  color: yellow;
}

.custom-chart-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: orangered;
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  cursor: move;
}

.hidenMove .custom-chart-card-title {
  cursor: default;
}

.custom-chart-card-value {
  font-size: 2em;
  font-weight: bold;
}

.custom-chart-card-status {
  text-transform: uppercase;
}

.custom-chart-card-image {
  opacity: 0.15;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 50%;
  object-fit: contain;
  pointer-events: none;
}

.compass {
  background-size: cover;
  background-position: center;
  position: relative;
  margin: 0 auto;
  margin-bottom: 10px;
  pointer-events: none;
}

.compass-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 80%;
  height: 80%;
  pointer-events: none;
}

.custom-chart-card-date {
  color: #a0a0a0;
  font-size: 12px;
}

.custom-chart-card-date-range {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  position: relative;
  text-align: center;
}

.custom-chart-card-body {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.custom-chart-container {
  position: relative;
}

.custom-chart-resize-detector {
  width: 80%;
  height: 80%;
}

.custom-chart-action-box {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 2;
  cursor: pointer;
}

.custom-chart-modal-fullscreen {
  background-color: #fff;
  width: 80vw;
  margin: 50px auto 0 auto;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  height: 80vh;
  overflow: hidden;
}
.custom-chart-modal-fullscreen .chart-fullscreen-content {
  height: 80%;
  max-width: 50%;
  margin: auto;
  position: relative;
}

.time-tooltip {
  position: fixed;
  background-color: white;
  display: inline-block;
  border-radius: 100%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.horizontal-gauge-container {
  position: relative;
  /* height: 100%; */
  /* width: 100%; */
  /* min-height: 80px; */
}

.horizontal-gauge {
  /* position: relative;
  top: 15%;
  height: 35%;
  width: 90%;
  margin: 0 auto; */

  position: relative;
  /* top: 15%; */
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

/* .horizontal-gauge {
  position: relative;
  margin: 0 auto;
  top: 10%;
  width: 90%;
  height: 40%;
} */

.horizontal-gauge svg {
  width: 100%;
}

.horizontal-gauge-info {
  position: absolute;
  bottom: 25%;
  margin: 0 auto;
  left: 0;
  right: 0;
  /* max-width: 100px; */
  /* font-size: 18px; */
  text-align: center;
  color: #fff;
}

.horizontal-gauge-currentvalue {
  /* font-weight: bold; */
  /* font-size: 16px; */
  margin: 0;
  /* margin-bottom: 6px; */
}

.horizontal-gauge-currentstate {
  margin-top: 8px;
}

.horizontal-gauge-subtitle {
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}
/* 
.horizontal-gauge-time {
  color: #fff;
  font-size: 0.9em;
  margin: 0;
  margin-top: 24px;
} */

.horizontal-gauge .gauge-chart-container {
  position: relative;
}

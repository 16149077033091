.threshold-editor-title {
  margin-bottom: 12px;
}

.threshold-data-table {
  width: 100%;
  margin-top: 6px;

  th,
  td {
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 5px;
    text-align: left;
    color: #3f4254;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  tbody tr {
    height: 62px;
  }
}

.threshold-editor-empty {
  text-align: center;
}

.pick-station-component {
  .pick-station-title {
    margin-bottom: 12px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
  }
  .pick-station-select{
    margin-bottom: 10px;
  }
}


.aggregate-data-table {
  .aggregate-data-table-header {
    font-weight: bold;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 1fr;
    column-gap: 10px; 
    padding: 12px 0;
  }

  .aggregate-data-table-row {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 1fr;
    column-gap: 10px;
    user-select: none;
    margin-bottom: 10px;
    background: white;
    border-radius: 4px;
    
    /* https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem */
    top: auto !important;
    left: auto !important;
  }
}

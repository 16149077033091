.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.not-found .lock-icon {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
}

.not-found h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.not-found p {
  font-size: 20px;
  color: #717171;
  margin-bottom: 50px;
}

.color-picker {
  position: relative;
}
.color-picker .color-picker-color {
  height: 52px;
  width: 100%;
  /* margin-bottom: -13px; */
  border: 3px solid lightgray;
  display: inline-block;
  border-radius: 6px;
  cursor: pointer;
}

.color-picker .color-picker-palette {
  position: absolute;
  z-index: 9;
  top: 60px;
}

.color-picker.disabled .color-picker-color {
  cursor: unset;
}

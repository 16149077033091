.value-list-card {
  /* padding: 24px; */
  border-radius: 6px;
  text-align: center;
  font-family: Helvetica;
  font-size: 15px;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  outline: none !important;
  border: none !important;
}

.value-list-card-buttons {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.value-list-card:hover .value-list-card-buttons {
  display: block;
}

.value-list-card-button {
  margin: 12px;
  font-weight: bold;
  cursor: pointer;
}

.value-list-card-button:hover {
  color: yellow;
}

.value-list-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: #145a5a;
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: move;
}

.hidenMove .value-list-card-title {
  cursor: default;
}

.public .value-list-card-title {
  cursor: default;
}

.value-list-card-value {
  font-size: 2em;
  font-weight: bold;
}

.value-list-card-status {
  text-transform: uppercase;
}

.value-list-card-image {
  opacity: 0.15;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  height: 50%;
  width: 50%;
  object-fit: contain;
  pointer-events: none;
}

.value-list-card-body {
  display: relative;
}

.value-list-card-content {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  text-align: center;
  width: 100%; */
  height: 90%;
}

.value-list-card table {
  width: 100%;
  border-collapse: collapse;
  color: white;
}

.value-list-card th,
.value-list-card td {
  padding: 6px;
  border: 1px solid white;
}
.value-list-card th {
  word-break: break-all;
}
.value-list-card table tbody tr:nth-child(n) {
  background: #4ac4ca;
}

.value-list-card table tbody tr:nth-child(2n),
.value-list-card table thead tr {
  background: #32b5bb;
}

.value-list-card-date {
  color: #a0a0a0;
  font-size: 12px;
}

.value-list-card table tr:first-child {
  background: #137175;
}

.value-list-table {
  table-layout: fixed;
  width: 100%;
}

.value-list-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

table.valuelist-table {
  table-layout: fixed;
  border-collapse: collapse;
  height: 90%;
}

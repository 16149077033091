.value-card {
  background: #009ce0;
  color: white;
  border-radius: 6px;
  text-align: center;
  font-family: Helvetica;
  font-size: 15px;
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none !important;
  border: none !important;
}

.value-card-body {
}

.value-card:hover .value-card-buttons {
  display: block;
}

.value-card-button {
  margin: 12px;
  font-weight: bold;
  cursor: pointer;
}

.value-card-button:hover {
  color: yellow;
}

.value-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  cursor: move;
}

.public .value-card-title {
  cursor: default;
}

.hidenMove .value-card-title {
  cursor: default;
}

.value-card-value {
  font-size: 2em;
  font-weight: bold;
}

.value-card-unit {
  font-size: 1em;
}

.value-card-status {
  text-transform: uppercase;
}

.value-card-image {
  opacity: 0.15;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  height: 50%;
  width: 50%;
  object-fit: contain;
  pointer-events: none;
}

.value-card-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  text-align: center;
  width: 100%;
}

.value-card-buttons {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.value-card-date {
  color: white;
  font-size: 12px;
  /* font-weight: bold; */
}

.value-card-body .status-value {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
  margin: auto;
}

.value-card-body .status-value > * {
  margin-right: 5px;
}

.value-card-parameter-name {
  text-transform: capitalize;
  font-size: 16px;
}

.container {
  background: #f8f8f9;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  background: white;
  box-shadow: 0 0 20px 0 #1d1f591a;
}

.headerRight {
  display: flex;
  align-items: center;
}

.logo {
  object-fit: contain;
  height: 45px;
  width: auto;
}

.body {
  padding: 12px;
  overflow-y: auto;
  flex: 1;
}

.mobileCardContainer {
  margin-bottom: 16px;
  box-shadow: 0 0 20px 0 rgb(29 31 89 / 10%);
}

.auth-footer {
  color: gray;
  margin-top: auto;
  padding: 24px 0;
  border-top: 3px solid #c7c7c74a;
}

.auth-footer .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.auth-footer .container .logo {
  width: 120px;
}

.auth-footer .container .logo img {
  width: 100%;
}


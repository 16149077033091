.chat-user-icon {
  z-index: 9999;
  position: relative;
  margin-top: 20px;
}
.chat-user-icon .wrapper {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 12px 28px 0 #00000033, 0 2px 4px 0 #0000001a;
  background-color: #fff;
}
.chat-user-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chat-user-icon .close-icon {
  box-shadow: 0 12px 28px 0 #00000033, 0 2px 4px 0 #0000001a;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #ffff;
  padding: 6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #111;
  display: none;
  cursor: pointer; 
}
.chat-user-icon .close-icon svg {
  width: 14px;
}
.chat-user-icon:hover .close-icon {
  display: flex;
}
.chat-user-icon .noti {
  box-shadow: 0 12px 28px 0 #00000033, 0 2px 4px 0 #0000001a;
  position: absolute;
  top: 0;
  right: 0;
  background: #f64e60;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.vertical-gauge {
  /* position: relative;
  top: 10%;
  left: 12%;
  width: 28%;
  height: 80%; */
  position: relative;
  top: 10%;
  left: 12%;
  width: 88%;
  height: 80%;
}

.vertical-gauge svg {
  flex: 1;
}

.vertical-chart-container {
  position: relative;
}

.vertical-gauge-info {
  position: absolute;
  top: 30%;
  left: 35%;
  /* right: 8%; */
  width: 50%;
  text-align: center;
  line-height: 1.3;
  color: #fff;
}

.vertical-gauge-title {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
  margin-bottom: 6px;
}

.vertical-gauge-subtitle {
  color: gray;
  text-transform: uppercase;
  margin: 0;
}

.vertical-gauge-time {
  color: gray;
  font-size: 0.9em;
  margin: 0;
  margin-top: 24px;
}

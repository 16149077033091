.location-map-position {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  text-shadow: 0px 0px 2.5px rgba(255, 255, 255, 0.6);
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.5);
  padding: 12px;
  max-width: 50%;
  border-radius: 6px;
  top: 48px;
  left: 50%;
  transform: translateX(-50%);
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid gray;
  background-color: yellow;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.mapboxgl-ctrl-bottom-right {
  right: 0;
  bottom: 0px;
  transition: 0.3s;
}
.mapboxgl-ctrl-attrib {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.arc-gauge {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 80px;
}

.arc-gauge svg {
  width: 100%;
}

.arc-gauge-container {
  position: relative;
}

.arc-gauge-info {
  position: absolute;
  bottom: 22%;
  margin: 0 auto;
  width: 100%;
  color: #ffffff;
}

.arc-gauge-info .unit {
}

.arc-gauge-title {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
  margin-bottom: 6px;
}

.arc-gauge-subtitle {
  color: gray;
  text-transform: uppercase;
  margin: 0;
  margin-top: 20px;
}

.arc-gauge-time {
  color: gray;
  font-size: 0.9em;
  margin: 0;
  margin-top: 24px;
}

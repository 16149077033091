.icon-item-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.dashboard-icon {
  border-radius: 6px;
  border-width: 2.5px;
  border-style: solid;
  border-color: #eeeeee;
  transition: 0.2s;
  overflow: hidden;
  position: relative;
  display: flex;
}

.dashboard-icon .icon {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  margin: auto;
}

.icon-item-container:hover input ~ .dashboard-icon {
  border-color: #80bfff;
}

.icon-item-container input:checked ~ .dashboard-icon {
  border-color: #3699ff;
}

.icon-container {
  display: flex;
}

.icon-item-container {
  height: 70px;
  width: 70px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.value-modal-header {
  margin-bottom: 16px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.value-modal-header-icon {
  cursor: pointer;
}


.value-modal-body .form-group {
  margin-bottom: 6px !important;
}

.value-modal-card {
  border: 2px solid lightgray;
  border-radius: 6px;
  cursor: pointer;
}

.value-modal-card:hover {
  border: 2px solid #3699ff;
}

.value-modal-card-text {
  padding: 10px 12px;
  background: #ececec;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.value-modal-card-title {
  font-size: 16px;
  margin-bottom: 6px;
}

.value-modal-card-description {
  margin: 0;
}

.value-modal-card-image {
  width: 100%;
  height: 120px;
  object-fit: contain;
  border-bottom: 1px solid lightgray;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.value-modal-body .color-picker .color-picker-color {
  width: 100%;
}

.date-picker-component .date-picker-title {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
}

.date-picker-component .date-picker {
  display: flex;
  align-items: center;
  width: 300px;
  justify-content: space-between;
  background-color: white;

}

.date-picker-component{
  width: 100%;
  margin-bottom: 16px;
}

.select-function-component {
  width: 100%;
  margin-bottom: 16px;
}

.select-function-component .select-function-title{
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.date-picker-component .form-label {
  font-size: 14px;
}


.select-function-component .select-function-body{
  display: flex;
  align-items: center;
  gap: 20px;
}

.select-function-component .select-function-body .select-multi-function{
  width: 500px;
}

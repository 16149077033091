.main-header.enduser {
  left: 0px;
}

.cl-search-map-container {
  min-width: 210px;
  display: flex;
  color: #4d4d4d;
  font-size: 14px;
}

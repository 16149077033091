.index-table {
  margin-top: 12px;
  margin-bottom: 24px;
  overflow-x: auto;
  position: relative;
}

.index-table table {
  width: 100%;
  min-width: 700px;
  border-collapse: collapse;
}

.index-table thead tr:first-child {
  color: white;
}

.index-table thead tr:first-child th {
  padding: 6px;
  border: 1px solid white;
  text-align: center;
  background: #4690dc;
}
.index-table thead tr:nth-child(2) td {
  padding: 6px;
  text-align: center;
  border: 1px solid white;
  background: lightblue;
}

.index-table tbody tr td {
  border-bottom: 1px solid #ccc;
  padding: 6px;
  text-align: center;
  color: #111;
}

.index-table-button {
  color: #b6b6b6;
  cursor: pointer;
  margin-right: 12;
}
.frozen {
  position: sticky;
  top: 0;
  right: -3px;
  background-color: #fff;
  z-index: 1;
}

.container {
  font-size: 13px;
  color: #b5b5c3;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  padding: 4px 7px;
  border-radius: 5px;
}

.container:hover {
  background-color: #f3f6f9;
}

.greeting {
  margin-right: 4px;
}

.fullName {
  margin-right: 10px;
  color: #7e8299;
  font-weight: 600;
}

.text {
  margin-left: 12px;
}

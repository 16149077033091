.gauge-chart-card {
  /* padding: 24px; */
  border-radius: 6px;
  text-align: center;
  font-family: Helvetica;
  font-size: 15px;
  position: relative;
  height: 100%;
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none !important;
  border: none !important;
}

.gauge-chart-card-body {
  /* padding: 24px; */
  height: 100%;
}

.gauge-chart-card-buttons {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.gauge-chart-card-content {
  text-align: center;

  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%; */
}

.gauge-chart-card:hover .gauge-chart-card-buttons {
  display: block;
}

.gauge-chart-card-button {
  margin: 12px;
  font-weight: bold;
  cursor: pointer;
}

.gauge-chart-card-button:hover {
  color: yellow;
}

.gauge-chart-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: orangered;
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  cursor: move;
}

.hidenMove .gauge-chart-card-title {
  cursor: default;
}

.public .gauge-chart-card-title {
  cursor: default;
}

.gauge-chart-card-value {
  font-size: 2em;
  font-weight: bold;
}

.gauge-chart-card-status {
  text-transform: uppercase;
}

.gauge-chart-card-image {
  opacity: 0.15;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  width: 50%;
  object-fit: contain;
  pointer-events: none;
}

.compass {
  /* width: 320px; */
  /* height: 320px; */
  background-size: cover;
  background-position: center;
  position: relative;
  margin: 0 auto;
  margin-bottom: 10px;
  pointer-events: none;
}

.compass-pointer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 80%;
  height: 80%;
  /* animation: rotate 5s linear infinite; */
  pointer-events: none;
}

/* @keyframes rotate {
  25% {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(360deg);
  }

  75% {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
} */

.gauge-chart-card-date {
  color: #a0a0a0;
  font-size: 12px;
  /* font-weight: bold; */
}

.dash-title-timestamp {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 6px;
  opacity: 0.6;
  color: #fff;
}

.gauge-chart-container {
  position: relative;
  overflow: hidden;
}

.gauge-point {
  transition: 0.5s;
}

.standard-chart-card {
  border-radius: 6px;
  font-family: Helvetica;
  font-size: 15px;
  position: relative;
  height: 100%;
  background: white;
  outline: none !important;
  border: none !important;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.standard-chart-card-buttons {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.standard-chart-card:hover .standard-chart-card-buttons {
  display: block;
}

.standard-chart-card-button {
  margin: 12px;
  font-weight: bold;
  cursor: pointer;
}

.standard-chart-card-button:hover {
  color: yellow;
}

.standard-chart-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: #145a5a;
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
  top: 0px;
  right: 0px;
  left: 0px;
}

.hidenMove .standard-chart-card-title {
  cursor: default;
}

.hidenMove .standard-chart-card-title {
  cursor: default;
}

.hidenMove .standard-chart-card-title {
  cursor: default;
}

.hidenMove .standard-chart-card-title {
  cursor: default;
}

.standard-chart-card-value {
  font-size: 2em;
  font-weight: bold;
}

.standard-chart-card-status {
  text-transform: uppercase;
}

.standard-chart-card-image {
  opacity: 0.15;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  height: 50%;
  width: 50%;
  object-fit: contain;
  pointer-events: none;
}

.standard-chart-card-body {
  display: relative;
}

.standard-chart-card-content {
  flex-grow: 1;
  height: max-content;
  padding: 20px 0px 20px 0px;
}

.standard-chart-card table {
  width: 100%;
  border-collapse: collapse;
  color: white;
}

.standard-chart-card th,
.standard-chart-card td {
  padding: 6px;
  border: 1px solid white;
}

.standard-chart-card table tbody tr:nth-child(n) {
  background: #4ac4ca;
}

.standard-chart-card table tbody tr:nth-child(2n),
.standard-chart-card table thead tr {
  background: #32b5bb;
}

.standard-chart-card-date {
  color: #a0a0a0;
  font-size: 12px;
  /* font-weight: bold; */
}

.date-range-box {
  position: fixed;
  right: 0;
  top: 0;
  width: 200px;
  background: white;
  z-index: 5;
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
  padding: 12px;
}

.date-range-box-item {
  background: #eaeaea;
  padding: 6px 12px;
  margin-bottom: 6px;
  border-radius: 6px;
  color: ocean;
  text-align: left;
  cursor: pointer;
}

.date-range-box-item:hover {
  background: lightgray;
}

.date-range-box-item--active {
  background: lightgray;
  font-weight: bold;
}

.diamond-marker .apexcharts-marker {
  transform-box: fill-box;
  transform: rotate(45deg);
  transform-origin: center;
}

.diamond-marker .apexcharts-marker {
  transition: 0s ease all !important;
}

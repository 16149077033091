.footer {
  padding: 12px 0;
}

.footer .container .nav {
  display: flex;
  align-items: center;
  justify-content: center;  
}

.footer .container .nav li {
  padding: 9px;
}

.footer .container .nav li a{
  color: #7E8299;
  transition: all .3s;
}

.footer .container li a:hover {
  color: #348dea;
}

.footer .container .copyright {
  margin-top: 8px;
  text-align: center;
}

.footer .container .copyright span:first-child {
  margin-right: 8px;
  color: #7E8299;
}

.footer .container .copyright a {
  margin-left: 4px;
}

@media (min-width: 768px) {
  .footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;  
  }
  .footer .container .nav {
    order: 2;
  }
  .footer .container .nav li {
    padding-bottom: 0;
  }
}


.standard-chart-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
}

.standard-chart-header select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 5px;
}

.standard-chart-header .form-group label {
  text-align: left;
  font-weight: bold;
  width: 100%;
}

/* .MuiTooltip-popper {
  z-index: 1 !important;
} */

.MuiTooltip-popper {
  z-index: 1;
}

.slider-modal-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  margin-top: 48px;
}

.slider-modal-button {
  text-align: center;
  cursor: pointer;
}

.slider-modal-button-icon svg {
  transform: scale(1.8);
  border: 1px solid #9c9c9c;
  padding: 24px;
  border-radius: 6px;
  margin-bottom: 38px;
  fill: #9c9c9c;
}

.slider-modal-button-text {
  color: #9c9c9c;
}

.slider-modal-button:hover svg {
  fill: #3699ff;
  border-color: #3699ff;
}

.slider-modal-button:hover .slider-modal-button-text {
  color: #1e1e2d;
}

.index-types-dropdown {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.index-types-dropdown-disabled {
  pointer-events: none;
  background: rgba(0, 0, 0, 0.09);
  color: rgba(0, 0, 0, 0.38);
}
.index-types-dropdown label {
  user-select: none;
}

.index-types-dropdown-value {
  border: 1px solid #ccc;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.index-types-dropdown-list {
  height: auto;
  width: 100%;
  overflow-y: auto;
  box-shadow: 0 2px 5px 1px #403c4329;
  position: absolute;
  padding: 12px 16px;
  top: 60px;
  left: 0;
  background: white;
  z-index: 9;
  box-sizing: border-box;
}

.index-types-dropdown-item-inner-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.index-types-dropdown-checkbox-wrapper {
  flex: 1;
  font-weight: 600;
  padding: 6px 0;
  color: #3f4254;
}

.index-types-dropdown-text {
  flex: 1;
}

.index-types-dropdown-icon {
  cursor: pointer;
  color: gray;
}

.index-types-dropdown-nested-items {
}
.index-types-dropdown-item {
  padding: 8px;
  padding-left: 16px;
  color: #3f4254;
}

.index-types-dropdown-item:hover {
  background: #eaeaea;
}

.index-types-dropdown .index-types-dropdown-item.disabled {
  opacity: 0.38;
  pointer-events: none;
}

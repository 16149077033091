.layers-container {
  .item-config-layer {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    transition: 0.3s;
    margin-bottom: 20px;
    margin-top: 20px;

    &.error {
      .item-config {
        // background-color: rgba(204, 0, 0, 0.1);
      }
      .be-left-item {
        background-color: rgba(246, 78, 95, 0.1);
      }
    }

    .be-left-item {
      position: relative;
      width: 80px;
      min-height: 80px;
      background-color: #f3f3f3;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .drag-button {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .item-config {
      flex: 1;
      transition: 0.3s;

      .item-config-header {
        padding: 18px;
        flex: 1;
        font-size: 14px;
        line-height: 22px;
        color: #1e1e2d;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 44px;

        .layer-info {
          margin-right: auto;
          min-width: 70%;
          .layer-name {
            font-weight: 500;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .layer-file-name {
            font-weight: 400;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .layer-action {
          margin-left: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          color: rgba(0, 0, 0, 0.54);

          .action-icon {
            cursor: pointer;
            margin-left: 15px;

            &.disabled {
              color: rgba(0, 0, 0, 0.2);
              cursor: default;
            }
          }
        }
      }

      .config-body {
        padding: 0px 20px;
        border-top: 1px solid rgba(0, 0, 0, 0);
        transition: 0.3s;
        max-height: 0px;
        opacity: 0;
        &.expand {
          padding: 20px 20px;
          max-height: 500px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          opacity: 1;
        }
      }
    }
  }
}

.layer-uploader {
  border: 1px solid rgba(0, 0, 0, 0.23);
  background-color: white;
  padding: 9px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  button.custom-button {
    padding: 6.5px 27px !important;
    font-size: 13px;
  }

  .file-name {
    color: rgba(0, 0, 0, 0.4);
    margin-left: 10px;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.error {
    border: 1px solid #f44336;
  }
}

.location-map-card {
  /* padding: 24px; */
  border-radius: 6px;
  text-align: center;
  font-family: Helvetica;
  font-size: 15px;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: white;
  outline: none !important;
  border: none !important;

  display: flex;
  flex-direction: column;
}

.location-map-card-buttons {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.location-map-card:hover .location-map-card-buttons {
  display: block;
}

.location-map-card-button {
  margin: 12px;
  font-weight: bold;
  cursor: pointer;
}

.location-map-card-button:hover {
  color: yellow;
}

.location-map-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: #145a5a;
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: move;
}

.public .location-map-card-title {
  cursor: default;
}

.hidenMove .location-map-card-title {
  cursor: default;
}

.location-map-card-value {
  font-size: 2em;
  font-weight: bold;
}

.location-map-card-status {
  text-transform: uppercase;
}

.location-map-card-image {
  opacity: 0.15;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  height: 50%;
  width: 50%;
  object-fit: contain;
  pointer-events: none;
}

.location-map-card-body {
  display: relative;
}

.location-map-card-content {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  text-align: center;
  width: 100%; */

  width: 100%;
  height: 100%;
}

.location-map-card table {
  width: 100%;
  border-collapse: collapse;
  color: white;
}

.location-map-card th,
.location-map-card td {
  padding: 6px;
  border: 1px solid white;
}

.location-map-card table tbody tr:nth-child(n) {
  background: #4ac4ca;
}

.location-map-card table tbody tr:nth-child(2n),
.location-map-card table thead tr {
  background: #32b5bb;
}

.location-map-card-date {
  color: #a0a0a0;
  font-size: 12px;
}

.location-map-card-content .tips-area {
  position: absolute;
  display: flex;
  bottom: 20px;
  left: 20px;
  right: 20px;
  transition: 0.3s;
}

.location-map-tips {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 10px 15px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
}

.location-map-tips span {
  font-size: 15px;
}

.location-map-tips span a {
  font-weight: 600;
  color: #3699ff;
}

.location-map-card .leaflet-control-zoom {
  display: none;
}

.location-map-card:hover .leaflet-control-zoom {
  display: block;
}

.location-map-card:hover .location-map-card-content .tips-area {
  bottom: 60px;
}
.mapboxgl-ctrl-bottom-right-hover:hover .mapboxgl-ctrl-bottom-right {
  bottom: 20px;
}

.standard-chart-section-style {
  display: grid;
  grid-template-columns: 2fr 6fr;
  /* grid-gap: 24px; */
}

.standard-chart-section-style-menu {
  border-right: 1px solid lightgray;
  padding-right: 12px;
}

.standard-chart-section-style-menu-item {
  padding: 10px 12px;
  margin-bottom: 6px;
  cursor: pointer;
  border-radius: 6px;
}

.standard-chart-section-style-menu-item.active {
  background: #3699ff;
  color: white;
}

.standard-chart-section-style-menu-item:hover {
  background: #3699ff;
  color: white;
}

.standard-chart-section-style-content {
  padding-left: 32px;
}

.standard-chart-section-style-content h3 {
  margin-bottom: 12px;
}

.standard-chart-section-style-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
}

.standard-chart-section-card {
  border: 2px solid lightgray;
  padding: 24px;
  margin-bottom: 24px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  opacity: 0.2;
  user-select: none;
  background: white;
}

.standard-chart-section-card.active {
  border: 2px solid #3699ff;
  opacity: 1;
}

.standard-chart-section-card:hover {
  border: 2px solid #3699ff;
}

.standard-chart-section-card span {
  font-weight: bold;
}

.standard-chart-section-card img {
  width: 42px;
  height: 42px;
  object-fit: contain;
  display: block;
  margin: 0 auto;
  margin-top: 12px;
}

.standard-chart-section .form-group {
  margin-bottom: 0;
}

.threshold-editor-title {
  margin-bottom: 12px;
}

.value-list-parameter-header {
  margin-top: 24px;
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 13px;
  display: grid;
  grid-template-columns: 17fr 7fr 6fr 6fr 6fr;
  column-gap: 12px;
  color: #3f4254;
}
.data-table-row {
  display: grid;
  grid-template-columns: 17fr 7fr 6fr 6fr 6fr;
  column-gap: 10px;
  user-select: none;
  margin-bottom: 10px;
  background: white;
  border-radius: 4px;
  align-items: center;
  /* https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem */
  top: auto !important;
  left: auto !important;
}
.threshold-editor-empty {
  text-align: center;
}

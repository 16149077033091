.location-map-modal-header {
  margin-bottom: 16px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.location-map-modal-header-icon {
  cursor: pointer;
}

.location-map-modal-body {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px; */
}

.location-map-modal-body .form-group {
  margin-bottom: 6px !important;
}

.location-map-modal-card {
  border: 2px solid lightgray;
  border-radius: 6px;
  cursor: pointer;
}

.location-map-modal-card:hover {
  border: 2px solid #3699ff;
}

.location-map-modal-card-text {
  padding: 10px 12px;
  background: #ececec;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.location-map-modal-card-title {
  font-size: 16px;
  margin-bottom: 6px;
}

.location-map-modal-card-description {
  margin: 0;
}

.location-map-modal-card-image {
  width: 100%;
  height: 120px;
  object-fit: contain;
  border-bottom: 1px solid lightgray;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.location-map-modal-body .color-picker .color-picker-color {
  width: 100%;
}

/* Select map style */
.map-style-field {
  margin-top: 8px;
  /* margin-bottom: 8px; */
}

.map-style-container {
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-right: 10px;
  margin-bottom: 5px;
}

.map-style-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.map-style-thumbnail-container {
  height: 100px;
  width: 100px;
  border-radius: 6px;
  border-width: 4px;
  border-style: solid;
  border-color: #fff;
  transition: 0.2s;
  overflow: hidden;
}

.map-style-thumbnail-container .map-style-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
}

.map-style-container:hover input ~ .map-style-thumbnail-container {
  border-color: #80bfff;
}

.map-style-container input:checked ~ .map-style-thumbnail-container {
  border-color: #3699ff;
}

.location-map-modal-body .valueLabel span {
  color: #fff;
}

.container {
}

.brand {
  font-size: 22px;
}

.topBar {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 12px;
  background: #1a1a27;
}

.topBarRight {
  display: flex;
  align-items: center;
}

.topBarCenter {
  flex: 1;
}

.breadcrumbsTitle {
  font-size: 19px;
  margin-right: 6px;
  flex-shrink: 0;
}

.breadcrumbs {
  background: white;
  padding: 6px 24px;
  overflow-x: auto;
  box-shadow: 0 0 6px 0 rgb(82 63 105 / 15%) !important;
  display: flex;
  align-items: center;
  min-height: 46px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.breadcrumbs::-webkit-scrollbar {
  display: none;
}

.breadcrumb {
  font-size: 17px;
  margin: 0 6px;
  flex-shrink: 0;
}

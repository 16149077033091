.dashboard-card {
  box-shadow: 0 0 20px 0 rgb(29 31 89 / 10%);
}

.dashboard-logo {
  height: 60px;
  object-fit: contain;
}

.react-grid-item.react-grid-placeholder {
  background: gray !important;
  border-radius: 6px;
}

.dashboard-title-1 {
  font-size: 18px;
  font-weight: 600;
  color: #3f4254;
}
.no-data-area {
  /* position: fixed; */
  /* top: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */

  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.no-data-area .no-data-label {
  margin: auto;
  color: #000;
}

.border-arrange .react-grid-layout {
  background: #e0e0e0;
  border-radius: 8px;
}

.dashboard-item-action-area {
  display: none;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}
.dashboard-item-card:hover .dashboard-item-action-area {
  display: flex;
}

.public {
  padding-top: 65px;
  background-color: #fff;
  overflow: auto;
  min-height: 90vh;
}
.public-no-header-footer {
  padding-top: 0px;
  background-color: #fff;
  overflow: auto;
  min-height: 100vh;
}

.public .dashboard-item-card:hover .dashboard-item-action-area {
  display: none;
}

.public .main-header {
  left: 0;
}
.dashboard-item-action-button-group {
  margin: auto;
  display: flex;
}

.dashboard-item-action-button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
  cursor: pointer;
  margin: 0px 6px;
  justify-content: center;
}
.dashboard-item-action-button:hover {
  color: yellow;
}
.dashboard-item-action-button > * {
  margin-right: 5px;
}

*:fullscreen *:-ms-fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  overflow: scroll !important;
}

#wrapper1,
#wrapper2 {
  overflow: auto;
}
#wrapper1 {
  height: 8px;
}
#div1 {
  width: 3840px;
  height: 1px;
}
#div2 {
  width: 3840px;
}

.threshold-editor-title {
  margin-bottom: 12px;
}

.threshold-data-table {
  width: 100%;
  margin-top: 24px;

  th,
  td {
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 5px;
    text-align: left;
    color: #3f4254;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  tbody tr {
    height: 62px;
  }
}

.threshold-editor-empty {
  text-align: center;
}

.historic-table-parameter {
  margin-top: 16px;
  .historic-table-parameter-header {
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 13px;
    display: grid;
    grid-template-columns: 4fr 3fr 2.5fr 1.5fr 2fr 1.5fr;
    column-gap: 12px;
    color: #3f4254;
  }

  .historic-table-parameter-row {
    column-gap: 12px;
    user-select: none;
    background: white;
    border-radius: 4px;
    padding: 3px 3px;
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 3fr 2.5fr 1.5fr 1.5fr 1.5fr;

    /* https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem */
    top: auto !important;
    left: auto !important;
  }
}

.chat-window {
  background-color: #f3f1f4;
  min-height: 526px;
  width: 380px;
  box-shadow:
    0 12px 28px 0 #00000033,
    0 2px 4px 0 #0000001a;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 9999;
  margin-right: 20px;
}

.chat-window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #3699ff;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.chat-window-header .chat-window-header-username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.icon-wrapper {
  margin-right: 10px;
  cursor: pointer;
}

.chat-window-main {
  padding: 24px 16px;
  background-color: #f3f1f4;
  flex-grow: 1;
  cursor: initial;
  height: 100%;
  overflow-y: auto;
}
.chat-window-label {
  font-weight: bold;
}

.chat-window-message {
  display: flex;
  /* align-items: center; */
  margin-bottom: 12px;
  max-width: 80%;
}
.chat-window-message p {
  margin: 0;
}

.chat-window-content {
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0 0 20px 0 #1d1f591a;
  background-color: white;
}

.chat-window-avatar {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 12px;
}

.chat-window-time {
  color: rgb(185, 185, 185);
  font-size: 0.8em;
  margin-top: 2px !important;
}

.chat-window-outbox {
  flex-direction: row-reverse;
  margin-left: auto;
}
.chat-window-outbox .chat-window-avatar {
  margin-left: 12px;
  margin-right: 0;
}

.chat-window-outbox p {
  text-align: right;
}

.chat-window-outbox .chat-message-error:hover {
  cursor: pointer;
  text-decoration: underline;
}

.chat-window-footer {
  margin-top: auto;
  padding: 12px 16px;
  border-top: 1px solid #e8e8e8;
  background: white;
}

.chat-window-footer form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-window-input {
  flex: 1;
  margin-right: 10px;
  background-color: #f0f2f5;
  border-radius: 30px;
  overflow: hidden;
}
.chat-window-input input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: #f0f2f5;
  padding: 10px;
}

.chat-window .send-button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.chat-window .file-wrapper {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;
  height: 60px;
  overflow-x: auto;
}
.chat-window .file-wrapper .file-image {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative;
  cursor: default;
}
.chat-window .file-wrapper .file-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.chat-window .remove-file,
.chat-window .remove-file {
  position: absolute;
  width: 24px;
  height: 24px;
  top: -8px;
  right: -8px;
  color: #fff;
  background: #111;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-window .file-file {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  background: #ccc;
  width: 128px;
  padding-left: 10px;
  border-radius: 10px;
  cursor: default;
  margin-right: 10px;
}
.chat-window .file-file .file-name {
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-decoration: underline;
}
.chat-window .file-file .file-name span {
  display: inline-block;
}
.chat-window .file-file .file-name span:first-child {
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-window .remove-file svg {
  width: 18px;
}
.chat-window .messsage-image {
  height: 120px;
  width: auto;
  position: relative;
}
.chat-window .messsage-image img {
  width: auto;
  height: 100%;
  object-fit: cover;
}
/* width */
.chat-window .file-wrapper::-webkit-scrollbar {
  height: 4px;
}

/* Track */
.chat-window .file-wrapper::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.chat-window .file-wrapper::-webkit-scrollbar-thumb {
  background: #637099;
  opacity: 0.8;
  border-radius: 5px;
}

/* Handle on hover */
.chat-window .file-wrapper::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}

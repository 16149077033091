/* Grid Data */
.location-map-modal-body .station-list {
}
.location-map-modal-body .grid-data {
  margin-top: 12px;
  margin-bottom: 12px;
  transition: 0.3s;
  overflow: hidden;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.location-map-modal-body .grid-data .grid-data-head {
  border-bottom: #ececec;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-weight: bold;
  padding: 10px 10px 10px 15px;

  background-color: #3699ff;
  color: #fff;
}

.location-map-modal-body .grid-data .grid-data-item {
  padding: 10px 10px 5px 15px;
  border-bottom: #ececec;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top: #ececec;
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 14px;
  font-weight: 400;
  color: #5a5b5f;
  background-color: #fff;
  position: relative;

  vertical-align: middle;
  /* background-color: rgba(230, 0, 0, 0.3); */

  /* -webkit-animation: successAppear 1s;
  -moz-animation: successAppear 1s; 
  -ms-animation: successAppear 1s; 
  -o-animation: successAppear 1s; 
  animation: successAppear 1s; */
}

.location-map-modal-body .grid-data .grid-data-head.action,
.location-map-modal-body .grid-data .grid-data-item.action {
  text-align: right;
}

.location-map-modal-body .grid-data .grid-data-item .MuiSvgIcon-root {
  margin-left: 4px;
  /* margin-right: 4px; */
  fill: rgb(182, 182, 182);
  cursor: pointer;
}

.location-map-modal-body .grid-data .config-area {
  border: none;
  padding: 0px;
  transition: 0.3s;
  overflow: hidden;
  max-height: 0px;
}

.location-map-modal-body .grid-data .config-area.edit {
  max-height: 500px;
  padding: 20px;
  border: 2px dashed #dcdcdc;
  background-color: #fff;
  -webkit-animation: open 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: open 0.5s; /* Firefox < 16 */
  -ms-animation: open 0.5s; /* Internet Explorer */
  -o-animation: open 0.5s; /* Opera < 12.1 */
  animation: open 0.5s;
}

.location-map-modal-body .grid-data .error-row .grid-data-item {
  background-color: rgba(244, 67, 54, 0.4);
}

.location-map-modal-body
  .grid-data
  .error-row
  .grid-data-item
  .MuiIconButton-sizeSmall {
  padding: 0px;
}
.location-map-modal-body
  .grid-data
  .error-row
  .grid-data-item
  .MuiSvgIcon-fontSizeSmall {
  font-size: 18px;
  fill: rgba(244, 67, 54, 1) !important;
}
.location-map-modal-body
  .grid-data
  .error-row
  .grid-data-item
  .MuiSvgIcon-root {
  fill: rgb(131, 130, 130);
}

@keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

/* Firefox < 16 */
@-moz-keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

/* Internet Explorer */
@-ms-keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

/* Opera < 12.1 */
@-o-keyframes open {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

@keyframes successAppear {
  from {
    background-color: rgba(74, 174, 32, 0.3);
  }
  to {
    background-color: rgba(74, 174, 32, 0);
  }
}

/* Firefox < 16 */
@-moz-keyframes successAppear {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes successAppear {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

/* Internet Explorer */
@-ms-keyframes successAppear {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

/* Opera < 12.1 */
@-o-keyframes successAppear {
  from {
    max-height: 0px;
  }
  to {
    max-height: 500px;
  }
}

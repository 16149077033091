.breadcrumbs {
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 8%);
  background-color: #ffffff;
  border-top: 1px solid #ebedf3;
  padding: 13px 0;
}

.breadcrumbs .breadcrumbs-container {
  display: flex;
  align-items: center;
}

.breadcrumbs .breadcrumbs-container h2 {
  font-size: 16px;
  font-weight: 400;
  margin-right: 32px;
}

.breadcrumbs .breadcrumbs-container .items ul {
  display: flex;
  align-items: center;
}

.breadcrumbs .breadcrumbs-container .items ul li {
  display: flex;
  align-items: center;
  margin-right: 7px;
}

.breadcrumbs .breadcrumbs-container .items ul li svg {
  font-size: 5px;
}

.breadcrumbs .breadcrumbs-container .items ul .home svg {
  font-size: 20px;
}

.breadcrumbs .breadcrumbs-container .items ul li a {
  color: #b5b5c3;
  padding-left: 7px;
  transition: all 0.3s;
}

.breadcrumbs .breadcrumbs-container .items ul li a:hover {
  color: #348dea;
}

.value-modal-header {
  margin-bottom: 16px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.value-modal-header-icon {
  cursor: pointer;
}

.value-modal-body {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px; */
}

.value-modal-body .form-group {
  margin-bottom: 6px !important;
}

.value-modal-card {
  border: 2px solid lightgray;
  border-radius: 6px;
  cursor: pointer;
}

.value-modal-card:hover {
  border: 2px solid #3699ff;
}

.value-modal-card-text {
  padding: 10px 12px;
  background: #ececec;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.value-modal-card-title {
  font-size: 16px;
  margin-bottom: 6px;
}

.value-modal-card-description {
  margin: 0;
}

.value-modal-card-image {
  width: 100%;
  height: 120px;
  object-fit: contain;
  border-bottom: 1px solid lightgray;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.value-modal-body .color-picker .color-picker-color {
  width: 100%;
}

.charts-table {
  margin-top: 24px;
}

.charts-table tr td,
.charts-table tr th {
  text-align: left;
  padding: 6px;
}

.charts-table tr td {
  padding: 10px 6px;
}

.charts-table table {
  width: 100%;
  border-collapse: collapse;
}

.chart-button {
  color: gray;
  cursor: pointer;
}

.chart-button-disabled {
  color: lightgray;
  cursor: not-allowed;
}

.menu-modal-header {
  margin-bottom: 16px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-modal-header-icon {
  cursor: pointer;
}

.menu-modal-body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.menu-modal-card {
  border: 2px solid lightgray;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* flex-direction: column; */
}

.menu-modal-card:hover {
  border: 2px solid #3699ff;
}

.menu-modal-card-text {
  padding: 10px 12px;
  background: #ececec;
  /* border-bottom-left-radius: 6px; */
  /* border-bottom-right-radius: 6px; */
  flex: 1;
  align-self: stretch;
}

.menu-modal-card-title {
  font-size: 16px;
  margin-bottom: 6px;
  margin-top: 0;
}

.menu-modal-card-description {
  margin: 0;
}

.menu-modal-card-image {
  /* flex: 1; */
  /* width: 100%; */
  /* height: 120px; */
  width: 82px;
  height: 72px;
  object-fit: contain;
  /* border-bottom: 1px solid lightgray; */
  /* border-top-right-radius: 6px; */
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.chat-actions {
  box-shadow: 0 12px 28px 0 #00000033, 0 2px 4px 0 #0000001a;
  position: absolute;
  top: -3px;
  right: -8px;
  background: #ffff;
  padding: 6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #111;
  display: none;
  cursor: pointer;
}
.chat-actions svg {
  width: 18px;
}
.chat-actions .action-list {
  position: absolute;
  color: #111;
  border-radius: 3px;
  box-shadow: 0 3px 7px rgb(0 0 0 / 13%), 0 0.6px 2px rgb(0 0 0 / 10%);
  width: max-content;
  background-color: #fff;
  padding: 6px 0;
  top: -65px;
  z-index: 8;
  display: none;
}
.chat-actions .action-list .action-item {
  display: flex;
  align-items: center;
  padding: 5px 18px;
  cursor: pointer;
}
.chat-actions .action-list .action-item:hover {
  background-color: #ebebeb;
}
.chat-actions.active {
  display: flex;
}
.chat-actions.active .action-list{
  display: block;
}
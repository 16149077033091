.user-sidebar {
  height: 100%;
  width: 309px;
  background: #ffffff;
  box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 25%);
  padding: 33px;
}
.user-sidebar .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
.user-sidebar .header h3 {
  font-weight: 400;
  font-size: 20px;
  margin: 0;
}

.user-sidebar .header .close {
  width: 24px;
  height: 24px;
  color: #7e8299;
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

.user-sidebar .header .close svg {
  font-size: 15px;
  color: #b5b5c3;
  transition: all 0.3s;
}

.user-sidebar .header .close:hover {
  color: #ffffff;
  background-color: #3699ff;
  border-color: #3699ff;
}

.user-sidebar .header .close:hover svg {
  color: #ffffff;
}

.user-sidebar .content .info {
  display: flex;
  align-items: center;
}

.user-sidebar .content .info .avatar {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  position: relative;
}

.user-sidebar .content .info .avatar img {
  object-fit: cover;
}

.user-sidebar .content .info .avatar span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #1bc5bd;
  border-radius: 50%;
  top: -6px;
  right: -6px;
  position: absolute;
  border: 2px solid #fff;
}

.user-sidebar .content .info .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.user-sidebar .content .main-info {
  padding-left: 16px;
}

.user-sidebar .content .main-info .name {
  font-size: 17px;
  margin-bottom: 8px;
  transition: all 0.3s;
  cursor: pointer;
}

.user-sidebar .content .main-info .name:hover {
  color: #3699ff;
}

.user-sidebar .content .main-info .role {
  color: #b5b5c3;
  margin-bottom: 10px;
}

.user-sidebar .content .main-info .mail {
  color: #b5b5c3;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.user-sidebar .content .main-info .mail svg {
  margin-right: 10px;
  font-size: 20px;
}

.user-sidebar .content .main-info .mail:hover {
  color: #3699ff;
}

.user-sidebar .content .main-info .button {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  margin-top: 8px;
}

.user-sidebar .content .separator {
  border-bottom: 1px dashed #ebedf3;
  margin: 20px 0;
}

.user-sidebar .content .user-menus ul li {
  display: flex;
  padding: 10px 0;
}

.user-sidebar .content .user-menus ul li .icon {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  color: #1bc5bd;
  background-color: #f3f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-sidebar .content .user-menus ul li .menu-content {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
}

.user-sidebar .content .user-menus ul li .menu-content p:first-child {
  font-weight: 500;
  transition: all 0.3s;
}

.user-sidebar .content .user-menus ul li .menu-content p:last-child {
  color: #b5b5c3;
  font-weight: 300;
}

.user-sidebar .content .user-menus ul li .menu-content:hover p:first-child {
  color: #3699ff;
}

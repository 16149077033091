.sub-chat-user-icon {
  z-index: 9999;
  position: relative;
  margin-top: 20px;
}
.sub-chat-user-icon .wrapper {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 12px 28px 0 #00000033, 0 2px 4px 0 #0000001a;
}
.sub-chat-user-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sub-chat-user-icon .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, .3);
  top: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
}
.sub-chat-user-icon .list {
  position: absolute;
  right: 100%;
  padding-right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  min-height: auto;
  max-height: 200px;
} 
.sub-chat-user-icon .list .wrapper {
  background-color: rgba(97, 97, 97, 0.92);
  color: #fff;
  border-radius: 4px;
  min-width: 200px;
  height: 100%;
  max-height: 200px;
  overflow-y: auto;
}
.sub-chat-user-icon .list .item {
  padding: 10px 15px;
  font-size: 14px;  
  background-color: transparent;
  transition: all .3s;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
} 
.sub-chat-user-icon .list .item svg {
  width: 16px;
} 
.sub-chat-user-icon .list .item:hover {
  background-color: #818181;
} 
.sub-chat-user-icon .list .wrapper::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.sub-chat-user-icon .list .wrapper::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.sub-chat-user-icon .list .wrapper::-webkit-scrollbar-thumb {
  background: #637099;
  opacity: 0.8;
  border-radius: 5px;
}

/* Handle on hover */
.sub-chat-user-icon .list .wrapper::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}
.sub-chat-user-icon:hover .list {
  display: block;
}
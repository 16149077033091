.data-files .date-picker {
  width: unset;
}
.data-files .date-picker .date-range-picker {
  width: unset;
  padding: 11.7px 16px;
}

.data-files-select .MuiInputBase-root {
  padding-right: 45px !important;
}

.station-general-markers {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  grid-column-gap: 10px;
}

.station-general-markers .marker {
  width: 42px;
  height: 42px;
  padding: 1px;
  border: 3px solid #eaeaea;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.station-general-markers .active-marker {
  border: 3px solid #3699ff;
}

.station-general-markers .marker img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uploaded-files {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin-top: 6px;
}
.uploaded-files .uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e1f0ff;
  padding: 12px;
  border-radius: 6px;
}
.uploaded-files .uploaded-file .uploaded-file-left {
  display: flex;
  align-items: center;
}
.uploaded-files .uploaded-file .uploaded-file-left .uploaded-file-text h5 {
  margin: 0 0 5px 0;
  font-size: 14px;
}
.uploaded-files .uploaded-file .uploaded-file-left .uploaded-file-text p {
  margin: 0;
}

.uploaded-files .uploaded-file .uploaded-file-left .uploaded-file-icon {
  margin-right: 12px;
}

.uploaded-files .uploaded-file h5 {
  color: #3699ff;
}

.uploaded-files .uploaded-file .uploaded-file-icon-button svg {
  cursor: pointer;
}

.uploaded-files.template {
  display: block;
}

.uploaded-files.template .uploaded-file {
  padding: 8px 10px;
}

.chat-box {
  position: fixed;
  right: 70px;
  bottom: 24px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 100;
}
.chat-box .chat-icon {
  width: 50px;
  height: 50px;
}
.chat-box .chat-icon:hover .chat-actions {
  display: flex;
}
.chat-box .chat-icon img {
  width: 100%;
  height: 100%;
}
.chat-box .chat-windows {
  position: fixed;
  bottom: 0;
  right: 130px;
  display: flex;
  flex-direction: row-reverse;
}
.chat-box .chat-user-icons {
  position: fixed;
  bottom: 90px;
  right: 70px;
  display: flex;
  flex-direction: column-reverse;
}
.chat-box .chat-icon .notification-message {
  background-color: rgba(29, 151, 246, 1);
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: max-content;
  padding: 5px 10px;
  border-radius: 4px;
  visibility: hidden;
  right: 110%;
}
.chat-box .chat-icon .all-noti {
  box-shadow:
    0 12px 28px 0 #00000033,
    0 2px 4px 0 #0000001a;
  position: absolute;
  top: 0;
  right: 0;
  background: #f64e60;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.chat-box .chat-icon .notification-message.show {
  visibility: visible; /* Show the snackbar */
  animation:
    fadein 1s,
    fadeout 1s 2.5s;
}
@-webkit-keyframes fadein {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    opacity: 1;
    right: 110%;
  }
}

@keyframes fadein {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    opacity: 1;
    right: 110%;
  }
}

@-webkit-keyframes fadeout {
  from {
    opacity: 1;
    right: 110%;
  }
  to {
    opacity: 0;
    right: -100%;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
    right: 110%;
  }
  to {
    opacity: 0;
    right: -100%;
  }
}

@media (max-width: 768px) {
  .chat-box .chat-windows {
    right: 0;
  }

  .chat-box,
  .chat-box .chat-user-icons {
    right: 24px;
  }

  .chat-window {
    width: 100vw !important;
    margin-right: 0 !important;
  }

  .chat-window-header-right .icon-wrapper {
    margin-right: 0;
    margin-left: 12px;
  }
}

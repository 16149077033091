.auth-header {
  background-color: white;
  box-shadow: 0 0 20px 0 #1d1f591a;
  z-index: 2;
}

.auth-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.auth-header .container .logo {
  height: 80px;
  margin: 0 auto;
}

.auth-header .container .logo img {
  width: auto;
  height: 100%;
}

.auth-header .container .actions {
  display: flex;
  align-items: center;
}

.auth-header .container .actions .button-groups .header-button {
  text-transform: uppercase;
  font-size: 13px;
  padding: 10px;
  font-weight: 400;
}

.auth-header .container .actions .button-groups .light-button {
  margin-left: 16px;
}

@media (min-width: 480px) {
  .auth-header .container .logo {
    width: 120px;
    margin: 0;
  }

  .auth-header .container .actions .button-groups .header-button {
    padding: 13px 30px;
  }
}
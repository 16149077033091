.select-dropdown-popper-dropdown {
  position: "relative";
  width: 152px;
  border-radius: 8px;
}
.select-dropdown-button {
  position: relative;
  color: black;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 12px 24px 12px 12px;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  background: #ffffff;
  overflow-y: auto;
  cursor: pointer;
}
.select-dropdown-button:hover {
  border: 1px solid #3699ff;
  border-radius: 8px;
}
.popper-button {
  z-index: 9999;
}
.select-dropdown-button-disabled {
  pointer-events: none;
  background: rgba(0, 0, 0, 0.09);
  color: rgba(0, 0, 0, 0.38);
}
.body-dropdown-button {
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  box-shadow: #d7d7d7;
  border-radius: 8px;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.item-dropdown-button {
  padding: 6px 12px 6px 12px !important;
}

.form {
  max-width: 1120px;
  margin: 0 auto;
  color: #3f4254;
  font-size: 14px;
}

.form .container {
  width: 80%;
  margin: 0 auto;
}

.form .small-container {
  width: 60%;
  margin: 0 auto;
}

.form .container .form-header {
  border-bottom: 3px dashed #c7c7c74a;
  padding-bottom: 0;
  margin-top: 12px;
  margin-bottom: 18px;
}

.form .container .form-header .form-title {
  font-size: 19.5px;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 400;
}

.form .container .form-button,
.form .small-container .form-button {
  font-size: 13px;
  font-weight: 400;
  padding: 13px 30px;
}

.terms .list .item {
  margin-bottom: 20px;
}

.terms .title {
  display: flex;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.terms .title .number {
  margin-right: 15px;
}

.terms .terms-content {
  line-height: 22px;
  font-size: 15px;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .form .small-container {
    width: 100%;
  }

  .forgot-form {
    margin: 0 24px;
  }

  .forgot-form-buttons > div {
    flex: 1;
  }

  .forgot-form-buttons button {
    width: 100%;
  }

  .login-form {
    margin: 0 6px;
  }

  .register-form-buttons > div {
    flex: 1;
  }

  .register-form-buttons button {
    width: 100%;
  }

  .register-form .container {
    width: 90%;
  }

  .register-form .container div:not(.register-form-buttons div) {
    margin-left: 0;
    margin-right: 0;
  }

  .register-form .note-msg {
    margin-bottom: 24px;
    padding-top: 0;
  }

  .auth-layout .background {
    margin-top: 145px;
  }

  .auth-layout .background p {
    line-height: 20px;
  }

  .auth-header {
    position: fixed;
  }
}

.react-switch-selector-wrapper {
  &:before {
    border-color: #465458 !important;
  }
}

.reeco-map-action {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  padding-right: 9px;
  z-index: 111;

  .action-buttonicon {
    background: rgba(17, 17, 17, 0.65);
    padding: 7px;
    transition: 0.3s;

    .MuiSvgIcon-root {
      color: #fff;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background: rgba(17, 17, 17, 0.85);
    }
  }

  .action-item {
    margin-bottom: 15px;
  }

  .zoom-buttons {
    margin-bottom: 30px;
  }

  .action-item {
    .item-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      transition: 0.3s;
      border-radius: 50%;
      background: rgba(17, 17, 17, 0.65);
      width: 30px;
      height: 30px;
      font-size: 20px;
      font-weight: 400;

      &:hover {
        background: rgba(17, 17, 17, 0.85);
      }
    }

    .item-button-zoom-in {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .item-button-zoom-out {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.sw-selector-200 {
      width: 200px;
      height: 30px;
      margin-bottom: 6px;
      margin-right: 54px;
    }

    &.sw-selector-240 {
      width: 240px;
      height: 30px;
    }
  }
}

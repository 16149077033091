@import "../components/map-action/map-action.style.scss";
@import "../components/layer-setting-modal/layer-setting-modal.style.scss";

.breadcrumbs {
  z-index: 101;
}

.reeco-map {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 80;

  .leaflet-control-attribution {
    a {
      display: none;
    }
  }

  .leaflet-control-layers {
    display: none;
  }

  .leaflet-control-zoom {
    display: none;
  }

  .leaflet-popup-close-button {
    padding: 13px 13px 0 0 !important;
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    background: white;
    color: #333;
    box-shadow: 0 3px 14px rgb(0 0 0 / 20%);
  }

  .mylocation-div-icon {
    margin: 0px !important;
    border-radius: 50%;
    background-color: rgba(54, 153, 255, 0);
    animation: blinker 3s linear infinite;
    background-size: 0%;
    transition: 0.3s;
  }

  .map-config {
    position: absolute;
    top: 45px;
    left: 30px;
    display: flex;
    flex-direction: row;
  }
}

.map-page-container {
  overflow: hidden;
  height: 100%;
  width: 100%;

  .mapboxgl-ctrl-top-right {
    display: none;
  }
}

@keyframes blinker {
  50% {
    background-color: rgba(54, 153, 255, 0.5);
    background-size: 160%;
  }
}

.labelRename {
  display: flex;
  font-weight: bold;
  gap: 2px;
  margin-bottom: 8px;
}

.labelRename p {
  color: red;
}

.groupBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  gap: 24px;
}

.requiredText {
  color: red;
  margin-top: 4px;
}

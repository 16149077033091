.attachment-image-card {
  border-radius: 6px;
  text-align: center;
  font-family: Helvetica;
  font-size: 15px;
  position: relative;
  height: 100%;
  overflow: hidden;
  background: white;
  outline: none !important;
  border: none !important;
}

.attachment-image-card-buttons {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  padding: 12px 0;
  z-index: 2;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.attachment-image-card:hover .attachment-image-card-buttons {
  display: block;
}

.attachment-image-card-button {
  margin: 12px;
  font-weight: bold;
  cursor: pointer;
}

.attachment-image-card-button:hover {
  color: yellow;
}

.attachment-image-card-title {
  font-weight: bold;
  color: white;
  position: relative;
  background: #b37a26;
  padding: 6px;
  z-index: 2;
  text-align: left;
  padding-left: 16px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  cursor: move;
}

.hidenMove .attachment-image-card-title {
  cursor: default;
}

.attachment-image-card-title .attachment-image-card-title-name span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
}

.public .attachment-image-card-title {
  cursor: default;
}

.attachment-image-card-content {
  text-align: left;
}

.attachment-image-card-value {
  font-size: 2em;
  font-weight: bold;
}

.attachment-image-card-body {
  position: relative;
  height: 100%;
}

.attachment-image-card-image {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.attachment-image-card-status {
  text-transform: uppercase;
}

.attachment-image-card-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

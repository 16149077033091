.container {
  position: sticky;
  top: 0;
  margin-left: -24px;
  background: white;
  z-index: 90;
  width: 100%;
  border: 1px solid #ddd;
  padding: 24px;
  padding-bottom: 12px;
}

.header {
  display: flex;
  align-items: space-between;
}

.headerLeft {
  flex: 1;
}

.headerTitle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.headerName {
  margin: 0;
  font-size: 1.5rem;
  margin-right: 12px;
}

.headerWorkspaceName {
  color: #696969;
  font-size: 1rem;
  margin-bottom: 6px;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  margin-top: 12px;
  padding-top: 12px;
}

.buttons {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonsEdit {
  flex: 1;
  display: flex;
  align-items: center;
}

.layoutSettingsBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  border-top: 1px solid #eee;
  padding-top: 12px;
}

.layoutSettingsBarLeft {
  flex: 1;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.helperText {
  color: #696969;
}

.switchContainer {
  display: flex;
  align-items: center;
}

.switchLabel {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.switchHelpIcon {
  margin-right: 6px;
}

.headerRightMenu {
  display: flex;
  gap: 2px;
  align-items: center;
}

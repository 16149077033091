.container {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.modal {
  width: 50%;
  height: 60%;
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.header {
  border-bottom: 1px solid #eee;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  margin: 0;
  padding: 0;
}

.body {
  flex: 1;
  display: flex;
}

.leftPanel {
  width: 20%;
  border-right: 1px solid #eee;
  height: 100%;
  padding: 12px;
}

.rightPanel {
  padding: 24px;
  background: #f8f8f8;
  flex: 1;
}

.label {
  font-weight: bold;
  margin-bottom: 12px;
}

.inputGroup {
  margin-top: 24px;
}

.rangeOption {
  padding: 12px 12px;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 6px;
}

.rangeOption:hover {
  background: #e1f0ff;
  color: #3699ff;
}

.rangeOptionActive {
  background: #e1f0ff;
  color: #3699ff;
  font-weight: bold;
}

.rangeOption:last-child {
  border-bottom: none;
}

.footer {
  border-top: 1px solid #eee;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.desktop-sidebar {
  display: none;
}

.sidebar > .wrapper {
  width: 286px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
  height: 100vh;
  background-color: #1e1e2d;
}

.sidebar > .wrapper > .inner > .header > .logo.organization-name {
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sidebar > .inactive-wrapper {
  width: 70px;
}

.sidebar > .inactive-wrapper > .inner > .header > .logo {
  display: none;
}

.sidebar > .inactive-wrapper > .inner > .content .MuiListItem-root p {
  display: none;
}

.sidebar > .inactive-wrapper > .inner > .content .MuiListItem-root .navi-icon {
  display: none;
}

.sidebar > .inactive-wrapper > .inner > .content .MuiListItem-root . {
}

.sidebar > .wrapper > .inner {
  background-color: #1e1e2d;
  height: 100%;
}

.sidebar > .wrapper > .inner > .header {
  background-color: #1a1a27;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 25px;
  height: 65px;
  border-bottom: none;
}

.sidebar > .wrapper > .inner > .header > .toggle > .icon {
  width: 26px;
  height: 26px;
  background-image: url("../../../../../assets/images/double-caret.svg");
  transition: all 0.3s;
  cursor: pointer;
}

.sidebar > .wrapper > .inner > .header > .toggle:hover .icon {
  background-image: url("../../../../../assets/images/double-caret-active.svg");
}

.sidebar > .wrapper > .inner > .header > .toggle > .inactive-icon {
  transform: rotate(180deg);
}

.sidebar > .wrapper > .inner > .content {
  margin: 13px 0;
  padding: 25px 0;
  overflow: auto;
  height: calc(100vh - 103px);
}
/* width */
.sidebar > .wrapper > .inner > .content::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.sidebar > .wrapper > .inner > .content::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
.sidebar > .wrapper > .inner > .content::-webkit-scrollbar-thumb {
  background: #637099;
  opacity: 0.8;
  border-radius: 5px;
}

/* Handle on hover */
.sidebar > .wrapper > .inner > .content::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}

.sidebar > .wrapper > .inner > .content {
  margin: 13px 0;
}
.sidebar > .wrapper > .inner > .content .list-header {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  color: #4c4e6f;
  padding: 0 25px;
  height: 40px;
  display: flex;
  align-items: center;
}
.sidebar > .wrapper > .inner > .content .MuiListItem-root {
  padding: 9px 25px;
  cursor: pointer;
  min-height: 44px;
  font-size: 13px;
  color: #a2a3b7;
  display: flex;
  align-items: center;
  font-weight: 400;
  transition: all 0.3s;
}

.sidebar > .wrapper > .inner > .content .MuiListItem-root:hover {
  background-color: #1b1b28;
  color: #fff;
}
.sidebar
  > .wrapper
  > .inner
  > .content
  .MuiListItem-root:hover
  .first-navi-icon {
  color: #3699ff;
}
.sidebar > .wrapper > .inner > .content .active-item {
  background-color: #1b1b28;
  color: #fff;
}

.sidebar > .wrapper > .inner > .content .MuiListItem-root p {
  margin-left: 10px;
  flex-grow: 1;
  word-break: break-all;
}

.sidebar > .wrapper > .inner > .content .MuiListItem-root .navi-icon {
  transition: all 0.3s;
}

.sidebar > .wrapper > .inner > .content .MuiListItem-root .first-navi-icon {
  margin-right: 5px;
  color: #494b74;
}

.sidebar > .wrapper > .inner > .content .MuiListItem-root .active-first-navi {
  color: #3699ff;
}

.sidebar > .wrapper > .inner > .content .MuiListItem-root .active-navi {
  transform: rotate(90deg);
}

.sidebar
  > .wrapper
  > .inner
  > .content
  .MuiListItem-root
  .active-navi.first-navi-icon {
  color: #3699ff;
}

.sidebar > .wrapper > .inner > .content .MuiListItem-root .main-icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  flex-shrink: 0;
}

.sidebar > .wrapper > .inner > .content .children {
  padding-left: 40px;
}

.sidebar > .wrapper > .inner > .content .sub-children {
  padding-left: 60px;
}

.sidebar > .wrapper > .inner > .content .sub-sub-children {
  padding-left: 99px;
}

.sidebar > .wrapper > .inner > .content .non-icon-sub-sub-children {
  padding-left: 85px;
}

.sidebar > .wrapper > .inner > .content .static-navi {
  padding-left: 69px;
}

.sidebar > .inactive-wrapper > .inner > .content .display-none {
  display: none;
}

@media (min-width: 1000px) {
  .desktop-sidebar {
    display: block;
  }
  .mobile-sidebar {
    display: none;
  }
}
.sidebar-action-item:hover {
  background-color: #3699ff !important;
  color: #fff !important;
}
.sidebar .rename-field {
  flex-grow: 1;
  padding-left: 10px;
}
.sidebar .rename-field input {
  color: #a2a3b7;
}
.sidebar .rename-field .MuiInput-underline:before {
  border-bottom-color: #a2a3b7;
}
.sidebar .rename-field:hover .MuiInput-underline:before {
  border-bottom-color: #a2a3b7 !important;
}
.rename-icon {
  transition: background-color 0.3s;
}
.rename-icon:hover {
  background-color: #e8e8e8;
}

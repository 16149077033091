.ranges-list-editor-title {
  margin-bottom: 12px;
}

.ranges-list-data-table {
  width: 100%;
  margin-top: 24px;

  th,
  td {
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  tbody tr {
    height: 62px;
  }
}

.ranges-list-editor-empty {
  text-align: center;
}

.ranges-list-editor {
  .range-item {
    padding: 10px 15px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    position: relative;
    margin: 10px 0px;

    .item-title {
      font-size: 13px;
      // display: flex;
      font-weight: 400;

      .item-color {
        width: 24px;
        height: 20px;
        border: 1px solid #e3e3e3;
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 5px;
        vertical-align: middle;
        border-radius: 2px;
      }
    }

    .item-description {
      word-break: break-word;
      margin-bottom: 6px;
    }

    .item-menu {
      position: absolute;
      padding: 6px 12px;
      bottom: -1px;
      right: -1px;
      background-color: #fff;
      border-color: #ccc;
      position: absolute;
      border: 1px solid #ddd;
      display: flex;
      border-top-left-radius: 6px;
      border-bottom-right-radius: 6px;

      span {
        color: #b6b6b6;
        cursor: pointer;
        margin: auto 4px;

        &:hover {
          color: gray;
        }
      }
    }

    .item-content-edit {
      overflow: hidden;
      transition: all 0.4s;
      max-height: 0px;
      opacity: 0;
      // padding: 0px;

      .MuiFormControl-marginNormal {
        margin-top: 9px;
      }

      &.editing {
        overflow: visible;
        border-top: 1px solid #e3e3e3;
        max-height: 500px;
        opacity: 1;
        padding: 30px 0px;
        transition: all 0.5s;
      }
    }
  }
}

.avatar-image-wrapper {
  position: relative;
  border: 1px solid #ccc;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding-top: 100%;
}
.avatar-image-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  object-fit: contain;
  opacity: 1;
}
@media screen and (max-width: 1279px) {
  .upload-wrapper {
    justify-content: flex-start !important;
  }
  .upload-wrapper > div {
    width: auto !important;
  }
  .upload-wrapper > div:first-child {
    margin-right: 20px;
  }
  .custom-upload {
    max-width: 115px !important;
    display: inline-block !important;
  }
  .main-content .avatar-image-wrapper {
    max-width: 33.333%;
    padding-top: 33.33333%;
  }
}

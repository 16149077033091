.sharing-requests .new {
  color: gray;
  font-weight: 600;
}
.sharing-requests .reject {
  color: #f64e60;
  font-weight: 600;
}
.sharing-requests .approve {
  color: rgb(74, 174, 32);
  font-weight: 600;
}
.sharing-requests
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 0;
}
.sharing-requests .comments-box {
  border-top: 2px dashed rgb(228, 228, 228);
  padding-top: 24px;
  margin-top: 42px;
}
.select-dropdown {
  z-index: 9999;
}
.data-sharing .uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #e1f0ff;
  padding: 12px;
  border-radius: 6px;
  width: 40%;
  margin-top: 20px;
}
.data-sharing .uploaded-file .uploaded-file-left {
  display: flex;
  align-items: center;
}
.data-sharing .uploaded-file .uploaded-file-left .uploaded-file-text h5 {
  margin: 0 0 5px 0;
  font-size: 14px;
}
.data-sharing .uploaded-file .uploaded-file-left .uploaded-file-text p {
  margin: 0;
}

.data-sharing .uploaded-file .uploaded-file-left .uploaded-file-icon {
  margin-right: 12px;
}

.data-sharing .uploaded-file h5 {
  color: #3699ff;
}

.data-sharing .uploaded-file .uploaded-file-icon-button svg {
  cursor: pointer;
}

.container {
  position: relative;
  height: 60vh;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container p {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #9c9c9c;
}

.date-range-picker-wrapper {
  position: relative;
}

.date-range-picker {
  border: 1px solid #ccc;
  padding: 10px 16px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  cursor: pointer;
}

.date-range-picker:hover {
  border: 1px solid #111;
}

.date-range-picker-icon {
  color: #757575;
}

.date-range-picker-value {
  display: block;
}

.materialui-daterange-picker-makeStyles-header-4 {
  padding: 6px 0 !important;
}
.materialui-daterange-picker-makeStyles-dateRangeBackdrop-3 {
  width: 100% !important;
  height: 100% !important;
}
